import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import { AuthProvider } from "./auth/authContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
root.render(
  <React.StrictMode>
    <ServiceWorkerWrapper />
    <AuthProvider>      
      <App />
    </AuthProvider>
  </React.StrictMode>
);
