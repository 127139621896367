import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import * as registerServiceWorker from "./registerServiceWorker";

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    if (registration && registration.waiting) {
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    }
  };

  useEffect(() => {
    registerServiceWorker.register({ onUpdate: onSWUpdate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Modal isOpen={showReload}>
      <ModalHeader>Update available</ModalHeader>
      <ModalBody>
        <p>Great News! SharePark has been updated</p>
        <p>You just need to refresh to get the latest version</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => reloadPage()}>
          Refresh
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceWorkerWrapper;
